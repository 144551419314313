<template>
  <!-- Section 1 -->
  <section class="w-full bg-white">
    <div class="py-10 px-6 mx-auto max-w-7xl md:px-8">

      <AppLink :to="{ name: 'ExploreDrop', params: { slug: 'spacesneks' } }" class="flex flex-col items-center w-full shadow overflow-hidden bg-gradient-to-bl from-green-400 via-green-600 to-green-500 transform duration-200 rounded-lg hover:-translate-y-1 hover:shadow-md md:rounded-xl md:flex-row md:items-stretch">
        <div class="flex flex-col justify-center w-full py-20 px-6 text-center text-green-50 md:w-2/3 md:mt-0">
          <p class="mb-4 text-3xl font-medium leading-none">Space Sneks</p>
          <h2 class="max-w-lg mx-auto text-4xl font-semibold md:leading-tight md:text-5xl">
            Minting now.<br>Your key to The Hotel.
          </h2>
        </div>
        <div class="w-full mt-7 md:w-1/3 md:mt-0 md:flex items-end bg-green-200">
          <img class="w-full" src="@/assets/img/sss/8.png" />
        </div>
      </AppLink>

      <div class="flex flex-col mt-10 md:flex-row md:mt-8 md:space-x-8">
        <AppLink :to="{ name: 'ExploreDrop', params: { slug: 'keycards' } }" class="flex flex-col items-center justify-center flex-1 overflow-hidden shadow text-center bg-gradient-to-tl from-blue-400 via-blue-600 to-blue-500 transform duration-200 hover:-translate-y-1 hover:shadow md:rounded-xl">
          <div class="flex flex-col px-10 pb-5 text-blue-50 space-y-6 pt-14 md:px-8">
            <h3 class="text-4xl font-semibold leading-none md:text-5xl">
              The Keycard Games
            </h3>
            <p class="text-xl font-light">
              Free to play, multi-round NFT challenge
            </p>
          </div>
          <div class="w-full bg-blue-300 py-6">
            <img src="@/assets/img/keycard.png" class="w-2/3 mx-auto">
          </div>
        </AppLink>

        <div class="relative flex flex-col items-center justify-center flex-1 py-32 mt-10 overflow-hidden text-center bg-gray-100 md:rounded-xl md:mt-0">
          <VipIcon class="w-20 h-auto" />
          <div class="flex flex-col px-10 space-y-6 md:px-8">
            <h3 class="text-3xl font-semibold leading-none md:text-4xl">
              Get on the A-List
            </h3>
            <p class="text-xl font-light text-gray-800">
              Add an email address to your account to make sure you don't miss new drops
            </p>
            <div class="mt-5 max-w-lg mx-auto">
              <form @submit="save" class="sm:flex sm:items-center">
                <div class="w-full sm:max-w-xs">
                  <label for="email" class="sr-only">Email</label>
                  <input type="email" v-model="profile.email" id="email" class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                </div>
                <DBtn type="submit" primary :loading="loading" class="ml-2">
                  Save
                </DBtn>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { updateEmail, ownProfile } from '@/gql/auth'
import { DBtn, VipIcon } from '@ui'
import AppLink from '@/components/roof/AppLink.vue'

const store = useStore()
const id = store.getters['auth/id']

const { result } = useQuery(ownProfile, { id })
const profile = useResult(result, {
  id,
  email: null,
}, ({ profile, account }) => ({
  id: account.id,
  email: account.email,
}))

const { mutate, loading } = useMutation(updateEmail, () => ({
  variables: profile.value
}))

const save = async (e) => {
  e.preventDefault()
  try {
    await mutate()
    notify({ type: 'success', text: 'Email saved' })
  } catch (err) {
    if (err.message.includes('accounts_email_key')) {
      notify({ type: 'error', title: 'Invalid email', text: 'Already used by another account' })
    } else {
      notify({ type: 'error', title: 'Unexpected error', text: err.message })
    }
  }
}
</script>